import React, {useEffect, useState} from 'react'
import {KTSVG} from 'src/marketConnect/helpers'
import AlertModal from 'src/utils/modals/AlertModal'
import ConnectPlatformStrip from './components/ConnectPlatformStrip'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Spinner from 'react-bootstrap/Spinner'

import {
  createPlatformSettingAsync,
  deletePlatformDataAsync,
  fetchConnectedPlatformAsync,
  retrivePlatformDataAsync,
  shopifyappUninstallAsync,
} from 'src/redux/actions/platformActions'
import {PlatformDataType, PlatformSettingsDataType} from 'src/interfaces/Platform.interface'
import {toast} from 'react-toastify'
import ConnectPlatformStrip1 from './components/ConnectPlatformStrip1'
import {Link, Navigate, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import { isActiveBoolean } from 'src/redux/actions/changeisActiveAction'
import { getApproveSubscriptionAsync, getUserPlanAsync } from 'src/redux/actions/pricingActions'
import useAppToast from 'src/hooks/useAppToast'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import { setShopifyConnectFirstTimeMessageShow } from 'src/redux/slices/authSlice'
import { getInitialSyncStatusAsync, refreshOauthForConnectPlatformAsync } from 'src/redux/actions/authActions'
import { setStepsData } from 'src/redux/slices/tourPopupSlice'
import { refreshOauthTokenData } from 'src/utils/constants'
import { fetchUserAccountDetailsAsync } from 'src/redux/actions/accountActions'


const ConnectedPlatforms = () => {
  const navigate = useNavigate();
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
  const [deleting, setDeleting] = useState(false)
  const [selectedPlatformDelete, setSelectedPlatformDelete] = useState<PlatformDataType>()

  const [deletionCompleted, setDeletionCompleted] = useState<boolean>(false) // New state variable
  const {fetchedConnectedPlatformLoading} = useAppSelector((state) => state.fetchConnectedPlatform)
  const fetchedConnectedPlatformData = useAppSelector(
    (state) => state.fetchConnectedPlatform.fetchedConnectedPlatformData
    
  )
  const { userCurrentSubscription, requestSubscriptionLoading} = useAppSelector((state) => state.pricing)
  const {connectedPlatformData,platformSettingModalData } = useAppSelector((state) => state.connectedPlatform)
  const {successToast, errorToast} = useAppToast()
  const {selectedPlatformId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const charge_id= searchParams.get("charge_id") 
  const contract_id = searchParams.get('contract_id')
  const billing_intent_id = searchParams.get('billing_intent_id')
  const success = searchParams.get('success')
  const userId = searchParams.get('user_id')
  const [isRefreshing, setIsRefreshing] = useState(false)
  const { stepsData } = useAppSelector(state=>state.tourPopup)

  const [isVisibleAlertModel, setIsVisibleAlertModel] =useState<boolean>(false)
  const { fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)

  useEffect(() => {
    dispatch(setStepsData({stepData:stepsData,visible:true}))
    if(window.location.search){
      let queryItems = window.location.search.slice(1,window.location.search.length).split('&')
      let params = {}
      for(let ser of queryItems){
        let keyval = ser.split('=')
        params[keyval[0]] = keyval[1]
      }
      params['platform_id'] = selectedPlatformId ?? null
      if(!success){
        dispatch(getApproveSubscriptionAsync({...params}))
        .then((response)=>{
          if (response?.payload?.status_code === 201 && response?.payload?.data) {
            successToast(response?.payload?.message)
            setIsVisibleAlertModel(true)
            if(response?.payload?.data?.is_subscribed=='true'){
              dispatch(setStepsData({stepData:stepsData,visible:true}))
            }
         }else{
          errorToast(response?.payload?.massage)
          setIsVisibleAlertModel(false)
         }
        })  // Dispatch the action with charge_id
      } 
    }
    
    // if ( contract_id && billing_intent_id) {
    //   let queryData = {
    //     contract_id:contract_id, 
    //     billing_intent_id: billing_intent_id,
    //     platform_id:'',
    //     charge_id:'' 
    //   }

    //   if(selectedPlatform){
    //     queryData['platform_id'] = selectedPlatformId+''
    //   }
    //   if(charge_id){
    //     queryData['charge_id'] = charge_id
    //   }
    //   // dispatch(getApproveSubscriptionAsync({...queryData}))
    //   // .then((response)=>{
    //   //   if (response?.payload?.status_code === 201 && response?.payload?.data) {
    //   //     successToast(response?.payload?.message)
    //   //     dispatch(getUserPlanAsync()).then((response) => {
    //   //       console.log('list of  userplan', response)
    //   //     })
    //   //  }else{
    //   //   errorToast(response?.payload?.massage)
    //   //   dispatch(getUserPlanAsync()).then((response) => {
    //   //     console.log('list of  userplan', response)
    //   //   })
    //   //  }
    //   // })  // Dispatch the action with charge_id
    //   // setIsVisibleAlertModel(true)
    // }
  }, [charge_id])

  
  const dispatch = useAppDispatch()

  const onDeleteStripe = (platform: PlatformDataType) => {
    console.log('data platform to delete', platform)
    setSelectedPlatformDelete(platform)
    setShowAlertModal(true)
  }


  // useEffect(() => {
  //   if (fetchedConnectedPlatformData && !fetchedConnectedPlatformLoading && deletionCompleted) {
  //     // Check if deletion is completed and loading is false
  //     dispatch(fetchConnectedPlatformAsync())
  //     setDeletionCompleted(false) // Reset deletionCompleted state
  //   }
  // }, []) // Dependency array includes deletionCompleted

  // useEffect(() => {
  //   dispatch(fetchConnectedPlatformAsync())
  // }, [])

  // useEffect(() => {
  //  if(platformSettingModalData){
  //   setSelectedPlatform(platformSettingModalData)
  //   setShowSettingModal(true)
  //  }
  // }, [platformSettingModalData])



  const onDeleteConfirmed = async () => {
    if (selectedPlatformDelete && selectedPlatformDelete.id !== undefined) {
      setDeleting(true)
      //uninstall shopify 
      if(fetchedUserAccountData?.source==="SHOPIFY"){
          const response = await dispatch(shopifyappUninstallAsync({id:selectedPlatformDelete?.id}))
          if(response.payload==200){
            toast.success('Channel deleted successfully!', {
              autoClose: 3000,
            })
          }else{
            toast.warning('Channel not deleted '+ " " +  response.payload?.massage , {
              autoClose: 3000,
            })
          }
          setDeleting(false)
          setShowAlertModal(false)
          await dispatch(fetchConnectedPlatformAsync())
      }else{
        await dispatch(deletePlatformDataAsync({id: selectedPlatformDelete?.id}))
        .then((res)=>{
          
          if(res.payload?.status_code==200){
            toast.success('Channel deleted successfully!', {
              autoClose: 3000,
            })
           dispatch(getInitialSyncStatusAsync({}))
          }else{
            toast.warning('Channel not deleted'+" "+res.payload?.massage , {
              autoClose: 3000,
            })
          }
        })
        .catch((err)=>{
          toast.error('Channel not deleted'+ err , {
            autoClose: 3000,
          })
        })
        setDeleting(false)
        setShowAlertModal(false)
        await dispatch(fetchConnectedPlatformAsync())

      }
      
      // setDeletionCompleted(true) // Update deletionCompleted state
    } else {
      console.error('Platform id is undefined.')
      toast.warning('Platform id is undefined.')
    }
     
  }

  return (
    <>
      <div>
        <div id='test1' className='p-4 pt-0 rounded'>
          <div className='d-flex justify-content-end'>
            <Link to='/platforms' className='btn btn-primary btn-sm' style={{fontSize: '14px'}}>
              Add new channel
            </Link>
          </div>
        </div>
      </div>

      {fetchedConnectedPlatformLoading || requestSubscriptionLoading ? (
        <div className='d-flex align-items-center justify-content-center h-100'>
          <h1>
            <Spinner animation='border' variant='primary' />
          </h1>
        </div>
      ) : (
        <div className='py-4 pt-0'>
          {fetchedConnectedPlatformData?.length > 0 ?
          <>
          {
            fetchedConnectedPlatformData.map((platform: any, index) => {
              if (platform.isMainStore === true) {
                return (
                  <ConnectPlatformStrip1
                    key={platform.id}
                    platform={platform}
                    onDelete={() => onDeleteStripe(platform)}
                  />
                )
              }
            })
          }

          {
            fetchedConnectedPlatformData.map((platform: any, index) => {
              if (platform.isMainStore === false) {
                return (
                  <ConnectPlatformStrip
                    key={platform.id}
                    platform={platform}
                    onDelete={() => onDeleteStripe(platform)}
                    onTokenRefresh={()=>{
                      setIsRefreshing(true)
                      dispatch(refreshOauthForConnectPlatformAsync({available_platform_name:platform?.name}))
                      .then((response)=>{
                        console.log('refresh oauth url ---->>',response.payload?.data);
                        localStorage.setItem(refreshOauthTokenData, JSON.stringify({ available_platform_name: platform?.name, user_id: fetchedUserAccountData?.id }))
                        window.open(response.payload?.data?.url,"_self")
                        setTimeout(() => {
                          setIsRefreshing(false)                        
                        }, 4000);
                      })
                      .catch(()=>{
                        setIsRefreshing(false)
                      })
                    }}
                    isRefreshing={isRefreshing}
                  />
                )
              }
            })
          }
          </>
          : 
          <div className="alert bg-light-primary d-flex align-items-center p-5 shadow-sm">
            <span className="svg-icon svg-icon-2hx svg-icon-primary me-8">
              <KTSVG path="media/icons/duotune/communication/com009.svg" className="svg-icon-muted svg-icon-3hx" />
            </span>
          
            <div className="d-flex flex-column">
              <h5 className="mb-1">No Platform is connected yet </h5>
              <span>Please connect a platform. Click here to connect <Link to={'/dashboard'} > <KTSVG path="media/icons/duotune/general/gen016.svg" className="svg-icon-primary svg-icon-hx" /></Link>  </span>
            </div>
          </div>
          }
        </div>
      )}


      <AlertModal
        isVisible={showAlertModal}
        inProgress={deleting}
        successBtnLabel={deleting ? 'Deleting...' : 'Confirm Delete' }
        closeModal={() => setShowAlertModal(false)}
        onDeleteConfirmed={onDeleteConfirmed}
        selectedPlatformDelete={selectedPlatformDelete}
      />

      <CommonAlertModal
          title = { (success && userId) ? "WooCommerce is successfully connected" : `You have subscribed to the selected plan. Your new plan is active.` 
            // : "You have successfully subscribed to the plan. You can now proceed further."
           }
          subtitle=''
          onSuccess={() => {
            setIsVisibleAlertModel(false)
            if(fetchedUserAccountData?.source=='WOOCOMMERCE'){
              if(!fetchedConnectedPlatformData?.length){
                dispatch(setShopifyConnectFirstTimeMessageShow({show:true,message:'Go ahead & Connect WooCommerce as your Primary store'}))
              }
              navigate('/dashboard')
            }else{
              dispatch(fetchUserAccountDetailsAsync())
              navigate('/listing-channels')
            }
          }}
          onCancel={() => {
            setIsVisibleAlertModel(false)
          
          }}
          cancelBtnTitle='Close'
          isVisible={isVisibleAlertModel}
          isTermsVisible={false}
          setIsVisible={() => {}}
          showCloseButton={false}
          iconPath='/media/icons/duotune/general/gen043.svg'
          iconClass="svg-icon-success svg-icon-4hx"
          successBtnClasses='btn btn-sm btn-success  m-2'
          successBtnTitle='Ok'
        />

     
    </>
  )
}

export default ConnectedPlatforms
