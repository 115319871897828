import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import { BillingPlan, BillingPlanDetailProps, UserSubscriptionPlan } from 'src/interfaces/Platform.interface'
import { cancelSubscriptionAsync, getUserPlanAsync } from 'src/redux/actions/pricingActions'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'

const CustomTooltip: FC<{ msg: string }> = ({ msg }) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{msg}</Tooltip>}>
      <i className='bi bi-info-circle text-dark'></i>
    </OverlayTrigger>
  )
}

const BillingPlanDetail: FC<BillingPlanDetailProps> = ({ activePlanDetail }) => {

  const [visibleSubscription, setVisibleSubscription] = useState(false)
  const { mainStoreData } = useAppSelector((state) => state.fetchConnectedPlatform)
  const [isCancelSubscriptionConfirm, setIsCancelSubscriptionConfirm] = useState<boolean>(false)
  const [isCanceling, setIsCanceling] = useState<boolean>(false)
  const [copySubscriptionMsg, setCopySubscriptionMsg] = useState('Copy')
  const dispatch = useAppDispatch()
  const { errorToast, successToast, warningToast } = useAppToast()



  return (
    <>
      {activePlanDetail ? (
        <>
         <div>
         <button className='w-100 btn btn-sm btn-primary fw-bold fs-6 px-3'>
            <span className='fs-5'>Current Plan</span>
          </button>
            <div>
              <div className='mx-3 my-2'>
                <div className='details-container'>
                  <div className='d-flex flex-row justify-content-between'>
                  <div className='detail-row m-2'>
                    <p className='fs-5 fw-bold text-gray-600 mb-0 text-center'>Active Plan</p>
                    <div className='separator'></div>
                    <p className='fs-5 fw-bold'>{activePlanDetail?.billing_plan.display_name}</p>
                  </div>
                  <div className='detail-row m-2'>
                    <p className='fs-5 fw-bold text-gray-600 mb-0 text-center'>Billing Price</p>
                    <div className='separator'></div>
                    <span className='badge badge-light-success text-success rounded-pill fs-6 px-4 py-2'>
                      {activePlanDetail?.billing_plan?.showcase_price?.value}{' '}
                      {activePlanDetail?.billing_plan.showcase_price.currency_iso} /{' '}
                      {activePlanDetail?.billing_plan.billing_frequency === 'ANNUAL'
                        ? 'Yearly'
                        : 'Monthly'}
                    </span>
                  </div>
                  <div className='detail-row m-2'>
                    <p className='fs-5 fw-bold text-gray-600 mb-0 text-center'>Limit</p>
                    <div className='separator'></div>
                    <p className='fs-5 fw-bold'>
                      {activePlanDetail.billing_plan.order_limit} Orders /{' '}
                      {activePlanDetail?.billing_plan.product_limit} SKUs
                    </p>
                  </div>
                  {activePlanDetail?.activated_on && (
                    <div className='detail-row m-2'>
                      <p className='fs-5 fw-bold text-gray-600 mb-0 text-center'>Subscribed On</p>
                      <div className='separator'></div>
                      <p className='fs-5 fw-bold'>
                        {moment(activePlanDetail?.activated_on).format('hh:mm A, DD-MM-YYYY')}
                      </p>
                    </div>
                  )}
                  {activePlanDetail?.valid_till && (
                    <div className='detail-row m-2'>
                      <p className='fs-5 fw-bold text-gray-600 mb-0 text-center'>Valid Till</p>
                      <div className='separator'></div>
                      <p className='fs-5 fw-bold'>
                        {moment(activePlanDetail?.valid_till).format('hh:mm A, DD-MM-YYYY')}
                      </p>
                    </div>
                  )}
                  {activePlanDetail?.cancelled_at && (
                    <div className='detail-row m-2'>
                      <p className='fs-5 fw-bold text-gray-600 mb-0 text-center'>
                        Subscription Status
                      </p>
                      <p className='fs-5 fw-bold text-danger'>
                        <span className='text-danger rounded-pill fs-5'>Cancelled</span> at{' '}
                        {moment(activePlanDetail.cancelled_at).format('hh:mm A, DD-MM-YYYY')}
                      </p>
                    </div>
                  )}
                  { activePlanDetail?.subscription_id ?  
                        <div className="row my-4 mt-2 d-flex flex-column" >
                            <div className="col">
                                <p className='fs-5 fw-bold text-gray-600 mb-0' >Subscription Id</p>
                                <div className='separator'></div></div>
                            <div className="col d-flex align-items-center">
                                    <OverlayTrigger overlay={<Tooltip>{copySubscriptionMsg}</Tooltip>} >
                                        <p className='fs-5 cursor-pointer text-hover-primary fw-bold mb-0' unselectable='on' onClick={()=>{
                                            setCopySubscriptionMsg('Copied')
                                            if(activePlanDetail?.subscription_id){
                                                navigator.clipboard.writeText(activePlanDetail?.subscription_id)
                                            }
                                        }} onMouseLeave={()=>{
                                            setCopySubscriptionMsg('Copy')
                                        }}  >
                                            {activePlanDetail?.subscription_id} 
                                        </p>
                                    </OverlayTrigger>
                            </div>
                        </div> : null } 
                        { activePlanDetail?.billing_plan.billing_frequency!=='FREE_FOREVER' && activePlanDetail?.isActive ? (
                            <>
                                <div className='d-flex justify-content-end py-3 px-2'>
                                    <button 
                                        onClick={() => {
                                        setIsCancelSubscriptionConfirm(true)
                                        }}
                                    type='submit' className='btn btn-sm btn-danger fs-6 fw-bold me-2'>
                                    Cancel Subscription
                                    </button>
                                </div>
                            </>
                        ) 
                        : null}
                  </div>
                </div>
              </div>
            </div>
         </div>
        </>
      ) : null}
      {/* <CommonAlertModal
        title={
          fetchedUserAccountData?.source === 'WIX'
            ? `You have to cancel your current plan from Wix admin portal`
            : `Are you sure you want to cancel the Subscribed plan? The Plan will be downgraded to Free Forever plan.`
        }
        subtitle=''
        successDisabled={isCanceling}
        successBtnTitle={isCanceling ? 'Updating...' : 'Yes Confirm'}
        isTermsVisible={false}
        isVisible={isCancelSubscriptionConfirm}
        onCancel={() => {
          setIsCancelSubscriptionConfirm(false)
        }}
        onSuccess={() => {
          if (fetchedUserAccountData?.source === 'WIX') {
            setIsCancelSubscriptionConfirm(false)
          } else {
            setIsCanceling(true)
            dispatch(
              cancelSubscriptionAsync({
                subscription_id: activePlanDetail?.subscription_id,
                platform_id: mainStoreData?.id,
              })
            ).then((response) => {
              if (response?.payload?.status_code === 201 && response?.payload?.data) {
                successToast(response?.payload?.message)
                setIsCanceling(false)
                setIsCancelSubscriptionConfirm(false)
                dispatch(getUserPlanAsync()).then((response) => {
                  console.log('list of  userplan', response)
                  setActivePlanDetail(response.payload?.data)
                })
              } else {
                errorToast(response?.payload?.message)
                setIsCanceling(false)
                setIsCancelSubscriptionConfirm(false)
                dispatch(getUserPlanAsync()).then((response) => {
                  console.log('list of  userplan', response)
                  setActivePlanDetail(response.payload?.data)
                })
              }
            })
          }
        }}
           
      /> */
      }
     
    </>
  )
}

export default BillingPlanDetail
