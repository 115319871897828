import clsx from 'clsx'
import moment from 'moment'
import React, {FC, useState} from 'react'
import { useNavigate } from 'react-router-dom'

type NEWS = {
  id: number
  type: string
  title: string
  description: string
  created_at: string
  updated_at: string
}

const Article: FC<{item: NEWS}> = ({item}) => {
  const [isReadMoreOn, setIsReadMoreOn] = useState(false)
  const navigate = useNavigate()

  return (
    <div className=' border-bottom' >
      <div className='card-body p-2' >
        <div className='d-flex flex-column '>
          <div className='rounded p-2 pb-0 d-flex justify-content-between align-items-center ' >
            <p className='mb-0 mb-0 lh-sm ' style={{fontSize:'12px'}} >{item.title}</p>
            <small className=' fs-9 ms-2  text-secondary text-end w-70px'>
              {moment(new Date(item?.created_at)).format('DD MMM YY')}
            </small>
          </div>
          <div className={clsx( "px-2 pb-2" , !isReadMoreOn ? '' : '' )}>
            <p className='fs-8 text-secondary mb-0 lh-sm'>{item?.description}
              {/* {item?.description?.substring(0, isReadMoreOn ? item.description.length : 115)}  */}
              <button className='btn btn-sm text-primary fw-bold text-end p-0 mt-0 pt-0 fs-8 ms-2' onClick={()=>{
            // if(item.description.length> 500){
            //   // window.open('/article/1', '_blank' )
            // }else{
            //   setIsReadMoreOn(!isReadMoreOn)
            // }
          }} >
            </button> </p>
            {/* { isReadMoreOn ? <i className="bi bi-chevron-double-up text-primary fw-bold fs-6"></i> : 'Read More...'}</button> </p> */}
            
          </div>
        </div>
        {/* <div className='text-end px-2 mt-0' >
          <button className='btn btn-sm text-primary fw-bold text-end p-0 mt-0 pt-0 fs-8' onClick={()=>{
            if(item.description.length> 500){
              // window.open('/article/1', '_blank' )
            }else{
              setIsReadMoreOn(!isReadMoreOn)
            }
          }} >{ isReadMoreOn ? <i className="bi bi-chevron-double-up text-primary fw-bold fs-6"></i> : 'Read More'}</button>
        </div> */}
      </div>
    </div>
  )
}

export default Article
