import React, {Fragment, useEffect, useState} from 'react'
import {useAppSelector} from 'src/redux/storeHooks'
import OrdersProductList from './OrdersProductList'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarAlt, faHashtag, faTruck, faWallet} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const OrderSummary1 = () => {
  const {orderDetails} = useAppSelector((state) => state.orderDetailsData)
  const [orderSummary, setOrderSummary] = useState<any | null>(null)

  function TruncatedMessage({message}) {
    // Ensure message is a string
    const messageText = message ? String(message) : ''

    // Initialize isTruncated state
    const [isTruncated, setIsTruncated] = useState(messageText.length > 50) // Adjust length for truncation
    const truncatedText = messageText.substring(0, 50) + '...' // Adjust length for truncation

    // Check if message is null or undefined
    if (!message) {
      return null // Return null or handle the case appropriately
    }

    return (
      <span
        // onMouseEnter={() => setIsTruncated(true)}
        // onMouseLeave={() => setIsTruncated(false)} // Change to false when leaving
        title={messageText} // Set full message as tooltip
      >
        {messageText}
      </span>
    )
  }

  useEffect(() => {
    if (orderDetails) {
      setOrderSummary(orderDetails)
    }
  }, [orderDetails])

  const customerDetails = orderSummary?.customer || null
  console.log('customerDetails', customerDetails)
  const ordersProductList = orderSummary?.line_items || null
  const orderShipmentDetails = orderSummary?.shipments || null
  console.log('orderShipmentDetails', orderShipmentDetails)

  return (
    <Fragment>
      {orderSummary && (
        <div className='row g-4 mb-4'>
          <div className='col-md-6 col-12'>
            <div className='card shadow-sm '>
              <div className='card-header'>
                <div className='card-title'>Order Details ({orderSummary?.api_code || ''})</div>
              </div>
              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <FontAwesomeIcon icon={faCalendarAlt} className='fs-2 me-2' />
                            <span>Pulled At</span>
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {orderSummary?.created_at
                            ? moment.utc(orderSummary.created_at).format('hh:mm A, DD-MM-YYYY')
                            : ''}
                        </td>{' '}
                        {/* Replace with actual date */}
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <FontAwesomeIcon icon={faWallet} className='fs-2 me-2' />
                            <span>Payment Method</span>
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {orderSummary?.payments[0]?.payment_mode}
                          {/* <img src='/media/svg/card-logos/visa.svg' className='w-50px ms-2' /> */}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <FontAwesomeIcon icon={faTruck} className='fs-2 me-2' />
                            <span>Shipping Method</span>
                          </div>
                        </td>
                        <td className='fw-bold text-end '>
                          {orderShipmentDetails[0]?.shipment_method || 'Standard'}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <FontAwesomeIcon icon={faHashtag} className='fs-2 me-3' />
                            <span>Main Store Order </span>
                          </div>
                        </td>
                        <td className='fw-bold text-end '>
                          {orderSummary?.platformorder_set[0]?.api_code || 'N/A'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Customer Details */}
          <div className='col-md-6 col-12'>
            {customerDetails && (
              <div className='card shadow-sm'>
                <div className='card-header'>
                  <div className='card-title'>Customer Details</div>
                </div>
                <div className='card-body pt-0'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                      <tbody className='fw-semibold text-gray-600'>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                              Customer
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <div className='d-flex align-items-center justify-content-end'>
                              <div className='symbol symbol-circle symbol-25px overflow-hidden me-3'>
                                  <div className='symbol-label'>
                                    <img
                                      src='/media/avatars/man_5.png' // Replace with your image path
                                      alt={customerDetails?.name}
                                      className='w-100'
                                    />
                                  </div>
                              </div>
                              <span className='text-gray-600 text-hover-primary'>
                                {customerDetails?.name}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-sms fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Email
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <a
                              href={`mailto:${customerDetails?.email}`}
                              className='text-gray-600 text-hover-primary'
                            >
                              {customerDetails?.email}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-phone fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Phone
                            </div>
                          </td>
                          <td className='fw-bold text-end'>{customerDetails?.phone}</td>
                        </tr>
                        <tr>
                          <td className='text-muted py-9'></td> <td></td>{' '}
                        </tr>
                        {/* Display address fields */}
                        {/* <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-home fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Address
                            </div>
                          </td>

                          <td className='fw-bold text-end'>
                            {customerDetails?.address.address_1},{' '}
                            {customerDetails?.address.address_2}, {customerDetails?.address.city},{' '}
                            {customerDetails?.address.state}, {customerDetails?.address.zip},{' '}
                            {customerDetails?.address.country}
                          </td>
                        </tr> */}
                        {/* Add more address fields as needed */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {!customerDetails && (
              <div className='alert alert-warning text-center'>Customer details not available.</div>
            )}
          </div>
        </div>
      )}

      {customerDetails && (
        <div className='row g-4 mb-4'>
          {/* Billing Address */}
          <div className='col-md-6 col-12'>
            {customerDetails?.billing_address &&(
              <div className='card shadow-sm'>
                <div className='card-header'>
                  <h2 className='card-title'>Billing Address</h2>
                </div>
                <div className='card-body'>
                  {/* Render billing address here */}

                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-0'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted col-6 '>Address</td>
                        <td className='fw-bold col-6'>
                          {`${
                            customerDetails?.billing_address?.address_1
                              ? customerDetails?.billing_address?.address_1 + ' , '
                              : ''
                          }`}

                          {`${
                            customerDetails?.billing_address?.address_2
                              ? customerDetails?.billing_address?.address_2 + ' , '
                              : ''
                          }`}

                          {`${
                            customerDetails?.billing_address?.city
                              ? customerDetails?.billing_address?.city + ' , '
                              : ''
                          } ${
                            customerDetails?.billing_address?.state
                              ? customerDetails?.billing_address?.state + ', '
                              : ''
                          } ${
                            customerDetails?.billing_address?.zip
                              ? customerDetails?.billing_address?.zip + ', '
                              : ''
                          }`}

                          {`${customerDetails?.billing_address?.country ?? ''}`}
                        </td>
                      </tr>
                      {/* Add more fields as needed */}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {!customerDetails?.billing_address && (
              <div className='card shadow-sm'>
              <div className='card-header'>
                <h2 className='card-title'>Billing Address</h2>
              </div>
              <div className='card-body'>
                {/* Render billing address here */}

                <table className='table align-middle table-row-bordered mb-0 fs-6 gy-0'>
                  <tbody className='fw-semibold text-gray-600'>
                    <tr>
                      <td className='text-muted col-6 '>Address</td>
                      <td className='fw-bold col-6'>
                        {`${
                          orderSummary?.billing_address?.address_1
                            ? orderSummary?.billing_address?.address_1 + ' , '
                            : ''
                        }`}

                        {`${
                          orderSummary?.billing_address?.address_1
                            ? orderSummary?.billing_address?.address_1 + ' , '
                            : ''
                        }`}

                        {`${
                          orderSummary?.billing_address?.city
                            ? orderSummary?.billing_address?.city + ' , '
                            : ''
                        } ${
                          orderSummary?.billing_address?.state
                            ? orderSummary?.billing_address?.state + ', '
                            : ''
                        } ${
                          orderSummary?.billing_address?.zip
                            ? orderSummary?.billing_address?.zip + ', '
                            : ''
                        }`}

                        {`${orderSummary?.billing_address?.country ?? ''}`}
                      </td>
                    </tr>
                    {/* Add more fields as needed */}
                  </tbody>
                </table>
              </div>
            </div>
            )}
          </div>

          {/* Shipment Address */}
          <div className='col-md-6 col-12'>
            {customerDetails?.shipping_address && (
              <div className='card shadow-sm'>
                <div className='card-header'>
                  <h2 className='card-title'>Shipping Address</h2>
                </div>
                <div className='card-body'>
                  {/* Render shipment address here */}

                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-0'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted col-6'>Shipping Address</td>
                        <td className='fw-bold col-6'>
                          {`${customerDetails?.shipping_address?.address_1 ? customerDetails?.shipping_address?.address_1 + ' , ' : null}`}
                          {customerDetails?.shipping_address?.address_2 ? customerDetails?.shipping_address?.address_2 + ' , ' : null }
                          {`${customerDetails?.shipping_address?.city ? customerDetails?.shipping_address?.city + ' , ' : ''} ${
                            customerDetails?.shipping_address?.state ? customerDetails?.shipping_address?.state + ' , ' : ''
                          } ${customerDetails?.shipping_address?.zip ? customerDetails?.shipping_address?.zip + ', ' : ''}`}
                          {`${customerDetails?.shipping_address?.country ? customerDetails?.shipping_address?.country : ''}`}
                        </td>
                      </tr>

                      {/* Add more fields as needed */}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {!customerDetails?.shipping_address && (
              <div className='card shadow-sm'>
              <div className='card-header'>
                <h2 className='card-title'>Shipping Address</h2>
              </div>
              <div className='card-body'>
                {/* Render shipment address here */}

                <table className='table align-middle table-row-bordered mb-0 fs-6 gy-0'>
                  <tbody className='fw-semibold text-gray-600'>
                    <tr>
                      <td className='text-muted col-6'>Shipping Address</td>
                      <td className='fw-bold col-6'>
                        {`${orderSummary?.shipping_address?.address_1 ? orderSummary?.shipping_address?.address_1 + ' , ' : null}`}
                        {orderSummary?.shipping_address?.address_2 ? orderSummary?.shipping_address?.address_2 + ' , ' : null }
                        {`${orderSummary?.shipping_address?.city ? orderSummary?.shipping_address?.city + ' , ' : ''} ${
                          orderSummary?.shipping_address?.state ? orderSummary?.shipping_address?.state + ' , ' : ''
                        } ${orderSummary?.shipping_address?.zip ? orderSummary?.shipping_address?.zip + ', ' : ''}`}
                        {`${orderSummary?.shipping_address?.country ? orderSummary?.shipping_address?.country : ''}`}
                      </td>
                    </tr>

                    {/* Add more fields as needed */}
                  </tbody>
                </table>
              </div>
            </div>
            )}
          </div>
        </div>
      )}

      {/* shipment_details */}

      <div className='card shadow-sm mb-4'>
        <div className='card-header'>
          <h5 className='card-title'>Shipment</h5>
        </div>
        {orderShipmentDetails?.length ? (
          <div className='card-body pt-0'>
            <div className='table-responsive'>
              <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
                <thead>
                  <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-175px text-middle'> Tracking Number</th>
                    <th className='min-w-100px text-middle'>Company</th>
                    <th className='min-w-175px text-middle'>Shipment Status</th>
                    <th className='min-w-100px text-middle'>Message</th>
                    <th className='min-w-100px text-middle'>Tracking</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {orderShipmentDetails.map((item, index) => (
                    <tr key={index}>
                      <td className='fw-bold text-middle'>{item?.tracking_number}</td>
                      <td className='fw-bold text-middle'>{item?.tracking_company}</td>
                      <td className='fw-bold text-middle'>
                        {item?.sync_status && item?.sync_status == 'ERROR'
                          ? 'FAILED'
                          : item?.sync_status}
                      </td>
                      <td className='fw-bold text-middle'>
                        <TruncatedMessage message={item?.sync_message} />
                      </td>
                      <td className='fw-bold text-middle'>
                        {item?.tracking_url && (
                          <a
                            href={item.tracking_url} // Use `item.tracking_url` for each shipment
                            className='btn btn-primary'
                            target='_blank'
                            rel='noreferrer'
                          >
                            Track Online
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className='card-body'>
            <p className='fs-5 text-muted fw-medium'>Not Shipped Yet</p>
          </div>
        )}
      </div>

      {/* Orders Product List */}
      {ordersProductList && (
        <OrdersProductList line_items={ordersProductList} order={orderDetails} />
      )}
    </Fragment>
  )
}

export default OrderSummary1
