import {faLink, faRotate} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, {FC, Fragment, useEffect, useState} from 'react'
import {Button, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {
  getOrderDetailsAsync,
  getOrdersListAsync,
  orderResyncAsync,
  shippingResyncAsync
} from 'src/redux/actions/orderActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import StatusButton from './StatusButton'
import {toast} from 'react-toastify'
import AppDatePickerRange from './components/AppDatePickerRange'
import useAppToast from 'src/hooks/useAppToast'
import ShipmentStatusButton from './components/ShipmentStatusButton'

const ColProductName: FC<{
  rowData: any
}> = ({rowData}) => {


  return (
    <div>
      <div>
        <p className=' d-inline-block text-truncate mb-0 me-2 ' >
          <Link
            className='fw-bold  fs-5 link-underline-info py-0 text-success text-decoration-underline mb-0'
            to={`/sales/details/${rowData?.id}`}
          >
            {rowData?.api_code}
          </Link>
        </p>
      </div>
    </div>                                  
  )
}
const CommonColumn: FC<{rowData: string}> = ({rowData}) => {
  return (
    <div className='row'>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{rowData}</Tooltip>}
      >
      <p className='d-inline-block text-truncate '  style={{maxWidth:'120px'}}>{rowData}</p>
      </OverlayTrigger>
    </div>
  )
}

const Orders = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const {orderListLoading, orderList, orderPaginationData} = useAppSelector((state) => state.order)
  const {fetchedConnectedPlatformData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const [searchParams] = useSearchParams()
  let sync_status = searchParams.get('sync_status')
  let from_date = searchParams.get('from_date')
  let to_date = searchParams.get('to_date')
  let platform_id = searchParams.get('platform_id')
  
  const [selectedSyncStatus, setSelectSyncStatus] = useState( sync_status ?? '')
  const [selectedPlatform, setSelectedPlatform] = useState( platform_id ?? '')
  const [selectedShipmentStatus, setSelectedShipmentStatus] = useState('')
  const [selectedStarDate, setSelectedStartDate] = useState('')
  const {successToast, errorToast} = useAppToast()
  const [selectedEndDate, setSelectedEndDate] = useState('')
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
    search_string: '',
    sync_status: sync_status ? sync_status : '',
    shipment_status: '',
    sort_by: 'api_created_at',
    sort_order: 'DESC',
    // api_created_at: '',
    api_created_at_min: from_date ? from_date : '',
    api_created_at_max: to_date ? to_date : '',
  })
  const handleResetFilters = () => {
    setPaginationData({
      page: 1,
      limit: 10,
      search_string: '',
      sync_status: '',
      shipment_status: '',
      sort_by: 'api_created_at',
      sort_order: 'DESC',
      api_created_at_min: '',
      api_created_at_max: '',
    });
    setSelectedPlatform('');
    // setSelectSyncStatus('');
    setSelectedShipmentStatus('');
    setSelectedStartDate('');
    setSelectedEndDate('');
    setSearchString('');
  };

  //resync component start
  const ResyncButton = ({orderId}) => {
    const handleResyncClick = async () => {
      console.log('Resync clicked for order:', orderId)

      // Dispatch your API call action here, passing the orderId

      try {
        // Dispatch your API call action here, passing the orderId
        dispatch(orderResyncAsync({failed_order_ids: [orderId]})).then((response) => {
          dispatch(
            getOrdersListAsync({paramData: {...paginationData}, selectedPlatform: selectedPlatform})
          )

          // Show toast notification after successful dispatch
          // toast.success(response?.payload?.message)
          successToast("Failed Order resyncing started, status changed to Pending")
        })
      } catch (error) {
        console.error('Error during order resync:', error)
        toast.error('Order resync failed. Please try again.')
      }
    }

    return (
      <Fragment>
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip id={`tooltip-${orderId}`}>Click to retry syncing the Order</Tooltip>}
        >
          <Button
            className='ms-3 '
            variant='link'
            style={{padding: '5px 10px', cursor: 'pointer'}}
            onClick={handleResyncClick}
          >
            <FontAwesomeIcon icon={faRotate} color='red' size='lg' />
          </Button>
        </OverlayTrigger>
      </Fragment>
    )
  }

   //resync component start
   const ShippmentResyncButton = ({shippingId}) => {
    const handleResyncClick = async () => {
      console.log('Resync clicked for order:', shippingId)

      // Dispatch your API call action here, passing the orderId

      try {
        // Dispatch your API call action here, passing the orderId
        dispatch(shippingResyncAsync({failed_shipping_ids: [shippingId]})).then((response) => {
          dispatch(
            getOrdersListAsync({paramData: {...paginationData}, selectedPlatform: selectedPlatform})
          )

          // Show toast notification after successful dispatch
          // toast.success(response?.payload?.message)
          successToast("Failed Shipment resyncing started, status changed to Pending")
        })
      } catch (error: any) {
        console.error('Error during order resync:', error)
        toast.error(error?.message || 'Shipment resync failed. Please try again.')
      }
    }

    return (
      <Fragment>
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip id={`tooltip-${shippingId}`}>Click to retry the shipment</Tooltip>}
        >
          <Button
            className='ms-3 '
            variant='link'
            style={{padding: '5px 10px', cursor: 'pointer'}}
            onClick={handleResyncClick}
          >
            <FontAwesomeIcon icon={faRotate} color='red' size='lg' />
          </Button>
        </OverlayTrigger>
      </Fragment>
    )
  }

  // Inside your component
  const columns = [
    {
      name: 'Channel Order#',
      cell: (row) => (row ? <ColProductName rowData={row} /> : null),
      sortable: true,
      sortField: 'api_code',
      maxWidth:"240px",
      style:{overflow:"hidden"}
    },

    {
      name: 'Order Date',
      cell: (row) =>row?.api_created_at
      ? moment.utc(row?.api_created_at).format('hh:mm A, DD-MM-YYYY')
      : '',
      sortable: true,
      sortField: 'api_created_at',
    },
    {
      name: 'Order Status',
      // width: '200px',
      cell: (row) =>
        row ? (
          row.platformorder_set[0]?.sync_status === 'ERROR' ||
          row.platformorder_set[0]?.sync_status === 'ERROR_UNLINKED' ? (
            <div className='d-flex w-75'>
              <StatusButton
                syncStatus={row.platformorder_set[0]?.sync_status}
                syncMessage={row.platformorder_set[0]?.sync_message}
              />

              <ResyncButton
                orderId={row.id}
                // Implement the onResyncClick function to handle resync logic
              />
            </div>
          ) : (
            <StatusButton
              syncStatus={row.platformorder_set[0]?.sync_status}
              syncMessage={row.platformorder_set[0]?.sync_message}
            />
          )
        ) : null,
      // sortable: true,
      // center: true,
      sortField: 'sync_status',
    },
    {
      name: 'Shipment Status',
      cell: (row) => {
        let buttonContent
        let buttonStyle
        let showResyncButton = false

        switch (row?.shipment_status) {
          case 'PENDING':
            buttonContent = 'Pending'
            buttonStyle = {
              backgroundColor: '#FFD700',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
            }
            break
          case 'SHIPPED':
            buttonContent = 'Shipped'
            buttonStyle = {
              backgroundColor: 'green',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
            }
            break
            case 'ERROR':
              buttonContent = 'Failed'
              buttonStyle = {
                backgroundColor: 'red',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
              }
              // showResyncButton = true;
              break
          default:
            buttonContent = 'N/A'
            buttonStyle = {
              backgroundColor: 'gray',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
            }
            break
        }

        // return <Button style={buttonStyle}>{buttonContent}</Button>
        return( 
          <>
        <ShipmentStatusButton
        syncStatus={row?.shipment_status}
        syncMessage={row?.shipments?.[0]?.sync_message}/>
        {showResyncButton && (
          <ShippmentResyncButton shippingId={row.id} />
        )}
      </>)
      },
      // center: true,
    },
    {
      name: 'Channel',
      cell: (row) =>
        row ? (
          <div className='d-flex align-items-center'>
            <img
              src={row.platform.platform_info.logo_ref}
              alt='Channel Logo'
              className='me-2'
              style={{width: '20px', height: '20px'}}
            />
            <span>{row.platform.display_name}</span>
          </div>
        ) : null,
      // style: {
      //   // width: '30px', // Adjust the width as needed
      //   overflow: 'hidden',
      //   textOverflow: 'ellipsis',
       
      // },
      
    },
    {
      name: 'Main Store Order #',
      cell: (row) =>
        row ? <CommonColumn rowData={row?.platformorder_set[0]?.api_code || 'N/A'} /> : null,
      // sortable: true,
      center: true,
      // width:"240px",
      style:{overflow:"hidden"}
    },
    // {
    //   name: 'Buyer',
    //   cell: (row) => {
    //     const customerName = row?.customer?.name;
    //     return customerName ? <CommonColumn rowData={customerName} /> : "N/A";
    //   },
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Total Price',
    //   cell: (row) => row? (
    //     <CommonColumn rowData={row?.total_price?.value} />
    //   ) :null,
    //   sortable: true,
    //   center: true,
    // },
  ]


  const handleSort = (ev, sortDirection) => {
    console.log('ev', ev)
    setPaginationData((pre) => {
      return {...pre, sort_by: ev.sortField, sort_order: (sortDirection + '').toUpperCase()}
    })
  }

  // useEffect(() => {
  //   setPaginationData((pre) => {
  //     return {
  //       ...pre,
  //       search_string: searchString,
  //       page: 1,
  //       sync_status: selectedSyncStatus, // Include selectedSyncStatus here
  //       shipment_status: selectedShipmentStatus,
  //       // api_created_at: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : '',
  //     }
  //   })
  // }, [selectedSyncStatus, selectedShipmentStatus])

  useEffect(() => {
    dispatch(
      getOrdersListAsync({paramData: {...paginationData}, selectedPlatform: selectedPlatform})
    )
  }, [paginationData, selectedPlatform])

  return (
    <>
      <div className='mb-3'>
        <span className='fw-bold text-lg ' style={{fontSize: '16px'}}>
          Sales Orders Logs
        </span>
      </div>
      <div className='card card-flush'>
        <div className="card-header">
          <div className="card-title">
            <input
              className='form-control mr-sm-2 input-sm '
              type='search'
              placeholder='Search'
              aria-label='Search'
              onChange={(ev) => {
                setSearchString(ev.target.value)
              }}
              onKeyDown={(ev) => {
                if (ev.key == 'Enter') {
                  setPaginationData((pre) => {
                    return {...pre, search_string: searchString, page: 1}
                  })
                }
              }}
              value={searchString}
            />
          </div>
          <div className='card-toolbar'>
            <div className='d-flex flex-md-row flex-column justify-content-md-end' >
              <div className='mx-md-2 my-1 my-md-0'>
                <select
                  className='form-select'
                  aria-label='Select platform'
                  value={selectedPlatform}
                  onChange={(ev) => {
                    setSelectedPlatform(ev.target.value)
                  }}
                >
                  <option value={''}>All Platforms</option>
                  {fetchedConnectedPlatformData.length &&
                    fetchedConnectedPlatformData.map((item) => {
                      if (!item?.isMainStore) {
                        return (
                          <option key={item?.id + 'platform'} value={item?.id}>
                            {item?.display_name}
                          </option>
                        )
                      }
                    })}
                </select>
              </div>
              <div className='mx-md-2 my-1 my-md-0'>
                <select
                  className='form-select'
                  aria-label='Select Sync Status'
                  value={paginationData.sync_status}
                  onChange={(ev) => {
                    // setSelectSyncStatus(ev.target.value)
                    setPaginationData((pre) => {
                      return {
                        ...pre,
                        page: 1,
                        sync_status: ev.target.value, // Include selectedSyncStatus here
                      }
                    })
                  }}
                >
                  <option value={''}>Order Status</option>
                  <option value={'SYNCED'}>SYNCED</option>
                  <option value={'PENDING'}>PENDING</option>
                  <option value={'ERROR'}>ERROR</option>
                </select>
              </div>
              <div className='mx-md-2 my-1 my-md-0'>
                <select
                  className='form-select'
                  aria-label='Select Sync Status'
                  value={paginationData.shipment_status}
                  onChange={(ev) => {
                    // setSelectedShipmentStatus(ev.target.value)
                    setPaginationData((pre) => {
                      return {
                        ...pre,
                        page: 1,
                        shipment_status: ev.target.value,
                      }
                    })
                  }}
                >
                  <option value={''}>Shipment Status</option>
                  <option value={'SHIPPED'}>SHIPPED</option>
                  <option value={'PENDING'}>PENDING</option>
                  <option value={'ERROR'}>ERROR</option>
                </select>
              </div>
              <div className='mx-md-2 my-1 my-md-0'>
                <button
                  className='btn fs-6 btn-sm bg-white  border text-dark w-250px h-30px align-items-center d-flex justify-content-center '
                  onClick={() => setShowDateRangePicker(!showDateRangePicker)}
                >
                    {selectedStarDate && selectedEndDate
                  ? `${moment(selectedStarDate).format('YYYY/MM/DD')}  -  ${moment(selectedEndDate).format('YYYY/MM/DD')}`
                  : 'Pick a Date Range'}
                </button>
                <AppDatePickerRange
                  isVisible={showDateRangePicker}
                  handleClose={() => setShowDateRangePicker(false)}
                  onPickRange={({ startDate, endDate }) => {
                    // Update the selected start and end dates
                    setSelectedStartDate(startDate);
                    setSelectedEndDate(endDate);
                    // Update pagination data with the selected date range
                    setPaginationData((pre) => ({
                      ...pre,
                      api_created_at_min: moment(startDate).format('YYYY-MM-DD'),
                      api_created_at_max: moment(endDate).format('YYYY-MM-DD'),
                      page: 1
                    }));
                  }}
                />
    
              </div>
              <div className='mx-md-2 my-1 my-md-0'>
                <button className='btn  btn-sm btn-primary w-100px fw-bold py-2' onClick={handleResetFilters}>
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          fixedHeader={true}
          data={orderList}
          columns={columns}
          onSort={handleSort}
          paginationTotalRows={orderPaginationData.count}
          pagination
          paginationServer
          progressPending={orderListLoading}
          progressComponent={<Spinner animation='border' />}
          onChangePage={(pageNumber, totalRows) => {
            setPaginationData((pre) => {
              return {...pre, page: pageNumber}
            })
          }}
          onChangeRowsPerPage={(currentPage) => {
            setPaginationData((pre) => {
              return {...pre, limit: currentPage}
            })
          }}
          customStyles={{
            table: {
              style: {
                height: '55vh',
              },
            },
            headCells: {
              style: {fontWeight: 'bold', fontSize: '14px'},
            },
            cells: {
              style: {
                padding: '10px',
                borderBottom: '1px solid gainsboro',
              },
            },
          }}
        />
      </div>
    </>
  )
}

export default Orders
