import React, {useEffect, useState} from 'react'
import {Alert} from 'react-bootstrap'

const InitialSyncAlert: React.FC = () => {
  const [isUserAdmin, setIsUserAdmin] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    if (localStorage.getItem('loggedInAdminAccessToken')) {
      setIsUserAdmin(true)
    }

    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Responsive styles
  const getAlertStyles = () => {
    if (screenWidth <= 767) {
      return {
        top: '0.5rem',
        left: '8rem',
        fontSize: '10px',
        width: '75%',
      }
    } else if (screenWidth <= 1024) {
      return {
        top: '0.5rem',
        left: '10rem',
        fontSize: '11px',
        width: '75%',
      }
    } else {
      return {
        top: '1.5rem',
        left: isUserAdmin ? '13rem' : '3rem',
        fontSize: isUserAdmin ? '11px' : '12px',
        width: 'auto',
      }
    }
  }

  const {top, left, fontSize, width} = getAlertStyles()

  return (
    <>
      <Alert
        variant='warning'
        className='d-flex p-2 position-absolute'
        style={{top, left, fontSize, width}}
      >
        <span className='indicator-progress' style={{display: 'block'}}>
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
        <div className='mx-2 text-center' style={{fontSize}}>
          We are currently fetching some important details from your connected accounts. This may
          take a few moments. Once complete, you'll be able to enable data synchronization between
          your platforms.
        </div>
      </Alert>
    </>
  )
}

export default InitialSyncAlert
