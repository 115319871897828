import React, { FC, Fragment, useState } from 'react';
import { Modal, Button, Tabs, Tab, Spinner } from 'react-bootstrap';

import { useDispatch } from 'react-redux'; // Import useDispatch
import { toast } from 'react-toastify';
import { PlatformDataType } from 'src/interfaces/Platform.interface';
import { uploadAsync } from 'src/redux/actions/uploadAction';
import { useAppDispatch } from 'src/redux/storeHooks';
import { accessSessionTokenKeyName } from 'src/utils/constants';

const CSVUploadModal :FC<{ show: boolean, handleClose: () => void, id: string, platformData:PlatformDataType|null }> = ({ show, handleClose, id, platformData }) => {
    // Component logic
    const [csvFile, setCSVFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false)
    const [filetype, setFiletype] = useState('IMPORT_PRODUCTS')
    const [sampleDownloading, setSampleDownloading] = useState(false)
    const dispatch = useAppDispatch(); // Initialize useDispatch
     
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0]; // Access the first file from the FileList
        console.log("selectedFile;",selectedFile, await selectedFile?.size )
        if (selectedFile) {
            setCSVFile(selectedFile);
        } else {
            console.error("No file selected.");
        }
    };

    const downloadSampleFile = async (type:'warehouses'|'products') => {
            setSampleDownloading(true)
            fetch(`${process.env.REACT_APP_API_URL}/api/download/${type}.csv`,{
                method:"GET",
                redirect:'follow',
                headers:{
                    Accept: 'application/json',
                    'Content-Type' : 'application/json',
                    responseType: "blob",
                    'Authorization' : `Bearer ${localStorage.getItem(accessSessionTokenKeyName)}`
                }
            })
            .then((res)=>res.blob())
            .then((result)=>{
                console.log('result', result)
                var url = window.URL.createObjectURL(result);
                var a = document.createElement('a');
                a.href = url;
                a.download = `sample_${type}.csv`;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again
                setSampleDownloading(false)
                toast.success('Sample file downloaded successfully')  
            })
            .catch((err)=>{
                console.log('err', err)
                toast.warning('Unable to download sample file.'+err)
                setSampleDownloading(false)  
            })

    }

    const handleUploadClick = async () => {
        if (csvFile instanceof File) {
            setLoading(true)
            const result = await  dispatch(uploadAsync({ id: id, csvFile, type:filetype }))
            console.log('result.payload', result.payload)
            if(result.payload?.success){
                toast.success(result.payload?.success)
                setCSVFile(null)
                handleClose()
                setLoading(false)
                return
            }
            
            if(result.payload?.data?.error){
                toast.warning(result.payload?.data?.error)
                setLoading(false)
                return
            }
            
            // Display success message using react-toastify

        }else{
            toast.warning('Please choose a file to upload')
        }
        
    };

    

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload CSV of { filetype=='IMPORT_PRODUCTS' ? 'Products' : 'Warehouses' } from {platformData?.display_name ?? '' }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Tabs
                    defaultActiveKey="IMPORT_PRODUCTS"
                    transition={false}
                    className="mb-3"
                    onSelect={(ev)=>{
                        // console.log('event name', ev);
                        if(ev){
                            setFiletype(ev)
                            setCSVFile(null)
                        }
                    }}
                    >
                    <Tab eventKey="IMPORT_PRODUCTS" title="Upload Products list">
                        <span >Please go to your <small className='fs-6 fw-semibold' >{platformData?.display_name ?? '' }</small> Seller Portal & download the Product list from there to upload it here.{['NOON'].includes(platformData?.name!) && <button className='btn btn-link btn-sm' disabled={sampleDownloading} onClick={()=>downloadSampleFile('products')} ><i className="bi bi-cloud-arrow-down text-primary"></i> Download { sampleDownloading ? <Spinner animation='border' className='fs-10' size='sm' /> : null } </button>} </span>
                    </Tab>
                    {
                        ['NOON'].includes(platformData?.name!) && (
                            <Tab eventKey="IMPORT_LOCATION" title="Upload Warehouse list">
                                <span >Please go to your <small className='fs-6 fw-semibold' >{platformData?.display_name ?? '' }</small> Seller Portal & download the Warehouse list from there to upload it here. Here is the sample csv for warehouse. <button className='btn btn-link btn-sm' disabled={sampleDownloading} onClick={()=>downloadSampleFile('warehouses')} ><i className="bi bi-cloud-arrow-down text-primary"></i> Download { sampleDownloading ? <Spinner animation='border' className='fs-10' size='sm' /> : null } </button> </span>
                            </Tab>
                        ) 
                    }
                </Tabs>
                    <br/>
                    {/* Input field for uploading CSV file */}
                    <input key={filetype+'change'} type="file" className='form-control' accept='.csv' onChange={handleFileChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant='secondary' onClick={handleClose}>
                        Close
                    </Button>
                    <Button disabled={loading||csvFile==null} variant='primary' onClick={handleUploadClick}>
                        {loading ? 'Uploading...' : 'Upload'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CSVUploadModal;






// import React, { FC, Fragment, useState } from 'react';
// import { Modal, Button } from 'react-bootstrap';

// import { useDispatch } from 'react-redux'; // Import useDispatch
// import { uploadAsync } from 'src/redux/actions/uploadAction';
// import { useAppDispatch } from 'src/redux/storeHooks';



// const CSVUploadModal :FC<{ show: boolean, handleClose: () => void, id: string }> = ({ show, handleClose, id }) => {
//     // Component logic
//     const [csvFile, setCSVFile] = useState<File | null>(null);
//     const [relativePath, setRelativePath] = useState<string | null>(null); // State to hold relative path
//     const dispatch = useAppDispatch(); // Initialize useDispatch

//     // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     //     const selectedFile = event.target.files?.[0]; // Access the first file from the FileList
//     //     if (selectedFile) {
//     //         setCSVFile(selectedFile);
//     //     } else {
//     //         console.error("No file selected.");
//     //     }
//     // };
//     const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const selectedFile = event.target.files?.[0];
//         if (selectedFile) {
//           setCSVFile(selectedFile);
      
//           // Use the library to extract relative path (example with file-saver)
//           const reader = new FileReader();
//           reader.readAsDataURL(selectedFile);
//           reader.onload = (event) => {
//             const dataURL = event.target?.result as string;
//             const blob = new Blob([dataURL], { type: selectedFile.type });
//             const path = URL.createObjectURL(blob); // Create relative path
//             setRelativePath(path); // Set relative path state
//           };
//         } else {
//           console.error("No file selected.");
//         }
//       };
      
//       const handleUploadClick = () => {
//         try {
//             if (csvFile instanceof File) {
//                 if (!relativePath) {
//                     throw new Error("Relative path not available.");
//                 }

//                 dispatch(uploadAsync({ id: id,csvFile: relativePath })); // Pass relative path to action
//                 setCSVFile(null);
//                 handleClose();
//             } else {
//                 console.error("Invalid file object:", csvFile);
//                 throw new Error("Invalid file object.");
//             }
//         } catch (error) {
//             console.error("Error uploading CSV file:", error);
//         }
//     };
    

//     return (
//         <Fragment>
//             <Modal show={show} onHide={handleClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Upload CSV</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {/* Input field for uploading CSV file */}
//                     <input type="file" onChange={handleFileChange} />
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant='secondary' onClick={handleClose}>
//                         Close
//                     </Button>
//                     <Button variant='primary' onClick={handleUploadClick}>
//                         Upload
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </Fragment>
//     );
// };

// export default CSVUploadModal;
